export function parallax() {
  const $slideUpTrigger = $(".js-slideUpTrigger");
  const $fadeInTrigger = $(".js-fadeInTrigger");

  slideUp($slideUpTrigger);
  fadeIn($fadeInTrigger);
}

function slideUp(elemement) {
  const duration = 0.6;
  const delay = 0.3;

  let tweenSlideUp = TweenMax.staggerFrom(
    elemement,
    duration,
    { y: "60px", opacity: 0, ease: Elastic.ease },
    delay
  );

  // let tweenSlideUp = TweenMax.to(elemement, 0.5, { y: "0", opacity: 1 });

  let controller = new ScrollMagic.Controller();

  let slideUp = new ScrollMagic.Scene({
    triggerElement: ".js-slideUpTrigger",
    triggerHook: "onEnter", // onLeave(一番上)/onCenter(中央)/onEnter(一番下)
    reverse: true
  })
    .setTween(tweenSlideUp)
    // .addIndicators({ name: "slideup" })
    .addTo(controller);
}

function fadeIn(elemement) {
  const duration = 1;
  const delay = 0.3;

  let tweenFadeIn = TweenMax.staggerFrom(
    elemement,
    duration,
    { opacity: 0, ease: Elastic.ease },
    delay
  );

  let controller = new ScrollMagic.Controller();

  let fadeIn = new ScrollMagic.Scene({
    triggerElement: ".js-fadeInTrigger",
    triggerHook: "onEnter", // onLeave(一番上)/onCenter(中央)/onEnter(一番下)
    reverse: true
  })
    .setTween(tweenFadeIn)
    // .addIndicators({ name: "fadein" })
    .addTo(controller);
}
