import { hamburger } from "./modules/hamburger";
import { smoothScroll } from "./modules/smoothScroll";
import { parallax } from "./modules/parallax";

$(() => {
  hamburger();
  smoothScroll();
  parallax();
});

var timer = null;

$(window).on("scroll", function() {
  clearTimeout(timer);

  const scrollHeight = $(document).height();
  const scrollPosition = $(window).height() + $(window).scrollTop();
  const footerHeight = $(".footer").innerHeight();

  timer = setTimeout(function() {
    if ($(this).scrollTop() > 200) {
      $(".header_cont").addClass("is-scroll");
      $(".pageTop").addClass("is-scroll");
    } else {
      $(".header_cont").removeClass("is-scroll");
      $(".pageTop").removeClass("is-scroll");
    }

    if (scrollHeight - scrollPosition <= footerHeight) {
      $(".pageTop").css({
        position: "absolute",
        bottom: footerHeight + 20
      });
    } else {
      $(".pageTop").css({
        position: "fixed",
        bottom: "40px"
      });
    }
  }, 10);
});
