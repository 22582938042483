import { toggleClass } from "./toggleClass";

export function hamburger() {
  const $hamburger = $(".js-hamburger");
  const $gnav = $(".js-gnav");
  const $gnavList = $(".js-gnavList");

  $hamburger.on("click", () => {
    $hamburger.toggleClass("is-open");
    $gnav.toggleClass("is-open");
  });
}
