export function smoothScroll() {
  $('a[href^="#"]').click(function() {
    // スクロールの速度
    var speed = 400; // ミリ秒で記述
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top - $(".header_cont").outerHeight();
    $("body,html").animate({ scrollTop: position }, speed, "swing");

    if ($(".js-hamburger").hasClass("is-open")) {
      $(".js-hamburger").removeClass("is-open");
      $(".js-gnav").removeClass("is-open");
    }

    return false;
  });
}
